import React from 'react';
import HillcrestCard from '../components/HillcrestCard.js';
import single_hung from '../images/windows/single-hung.jpg';
import double_hung from '../images/windows/double-hung.png';
import premium_double from '../images/windows/premium-double.png';
import casement from '../images/windows/casement.png';
import bay from '../images/windows/bay.jpg';
import fixed from '../images/windows/fixed.jpg';

const styles = {
  windowCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '40px',
  },
};

function Windows() {
  return (
    <div className="page-content windows">
      <h2 className="page-title">Windows</h2>
      <p>Hillcrest Windows &amp; Remodeling is an authorized dealer of energy efficient windows manufatured by Windows by Unlimited, Inc. in Oak Creek, WI. Windows manufactured by Unlimited are the most durable and best performing hung windows available on the market today. Windows by Unlimited are also highly energy efficient, meaning savings on annual heating and cooling costs.</p>
      <div style={styles.windowCards}>
        <HillcrestCard image={single_hung} imageAlt="Single Hung" label="Single Hung" sublabel="Silver Series 1000 - Premium All Welded Vinyl Single Hung" />
        <HillcrestCard image={double_hung} imageAlt="Double Hung" label="Double Hung" sublabel="Gold Series 2000 - Premium All Welded Vinyl Double Hung" />
        <HillcrestCard image={premium_double} imageAlt="Premium Double Hung" label="Premium Double Hung" sublabel="Platinum Series 3000 - Premium All Welded Double Hung" />
        <HillcrestCard image={casement} imageAlt="Casement Windows" label="Casement Windows" sublabel="5000 Series - Casement Windows" />
        <HillcrestCard image={single_hung} imageAlt="Patio Doors" label="Patio Doors" sublabel="6000 Series - Patio Doors" />
        <HillcrestCard image={bay} imageAlt="Bay, Bow, and Garden Windows" label="Bays/Bows/Garden" sublabel="7000 Series - Bays, Bows, &amp; Garden Windows" />
        <HillcrestCard image={fixed} imageAlt="Fixed and Specialty Windows" label="Fixed &amp; Specialty Windows" sublabel="8000 Series - Fixed &amp; Specialty Windows" />
        <HillcrestCard image={single_hung} imageAlt="Basement Windows" label="Basement Windows" sublabel="Freedom Basement Windows - The Next Generation of Basement Windows" />
      </div>

    </div>
  );
}

export default Windows;
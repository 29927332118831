import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

const styles = {
  header: {
    backgroundColor: "#f8f9fa",
  },
  navbar: {

  },
  nav: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
  },
  navlink: {
    fontWeight: "600",
    fontSize: "18px",
    margin: "auto 12px",
  },
};

function Header(props) {

  return (
    <header style={styles.header} className="header">
      <div className="navigation">
        <Navbar style={styles.nav} collapseOnSelect expand="lg" bg="light" variant="light">
          <Navbar.Brand as={Link} to="/"><img src={logo} alt="Hillcrest logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={styles.navbar} className="ml-auto">
              <Nav.Link as={Link} to="/about" style={styles.navlink}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/windows" style={styles.navlink}>Windows</Nav.Link>
              <Nav.Link as={Link} to="/interior" style={styles.navlink}>Interior Remodeling</Nav.Link>
              <Nav.Link as={Link} to="/exterior" style={styles.navlink}>Exterior Remodeling</Nav.Link>
              <Nav.Link as={Link} to="/contact" style={styles.navlink}>Contact Us</Nav.Link>
              <Nav.Link as={Link} to="/gallery" style={styles.navlink}>Photo Gallery</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}

export default Header;
import React from "react"

const styles = {
    copyright: {
        marginRight: "20px",
    },
    icon: {
        width: "35px",
        height: "35px",
        marginTop: "15px",
        marginLeft: "10px",
        marginRight: "10px",
    },
    footer: {
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        paddingTop: "20px",
    },
};


function Footer(props) {
    return (
        <div style={styles.footer} className="footer">
            <div className="footer-content">
                <p style={styles.copyright}>&copy; {(new Date().getFullYear())} Hillcrest Windows &amp; Remodeling LLC</p>
            </div>
        </div>
    )
}

export default Footer;
import React from 'react';
import HeroSlider from "../components/HeroSlider.js";

function Home() {
  return (
    <React.Fragment>
      <HeroSlider />
      <div className="page-content home">
        
      </div>
    </React.Fragment>

  );
}

export default Home;
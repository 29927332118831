import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import BrowserRouterLinks from './BrowserRouterLinks.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div className="main-content">
          <BrowserRouterLinks />
        </div>
        <Footer />
      </div>
    </BrowserRouter>

  );
}

export default App;
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const styles = {
    sublabel: {
        paddingBottom: '15px'
    }
};

function HillcrestCard(props) {
    return (
        <Card style={{margin: '15px'}} sx={{ width: 345}}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={props.image}
                    alt={props.imageAlt}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.label}
                    </Typography>
                    <Typography style={styles.sublabel} variant="body2" color="text.secondary">
                       {props.sublabel}
                    </Typography>
                    <Typography variant="button" color="text.secondary">
                       Learn More &rarr;
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default HillcrestCard;
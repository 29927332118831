import React from 'react';

function Interior() {
  return (
    <div className="page-content interior">
      <h2 className="page-title">Interior Remodeling</h2>
    </div>
  );
}

export default Interior;
import React from 'react';

function About() {
  return (
    <div className="page-content about">
      <h2 className="page-title">About</h2>
      <p>Thank you for your interest in <strong>Hillcrest Windows &amp; Remodeling LLC!</strong> We take pride in offering superior products and professional installation. We also provide you with outstanding warranties on both workmanship and products.</p>
      <p>If you're in the market for Energy Star Windows, we simply offer the best. There are 734 NFRC/Energy Star rated windows in the United States.</p>
      <p>The U-Factors on our windows are rated as number one out of those 734!</p>
      <p>This means you will have thee most energy efficient window on the market. We think that with rising fuel prices, it only makes sense to invest in the best. We guarantee that you will not find a better window at a lower price anywhere!</p>
      <p>Today's Energy Star Windows also add to the value of your home. They also eliminate scraping, caulking and painting forever. The ease of cleaning will amaze you. And, let's not forget lower heating and air conditioning bills! Our windows provide you with a <strong>Lifetime Transferable Warranty</strong> as well. <strong>All of our windows are custom made to fit your openings, and tilt-sash for easy cleaning.</strong></p>
      <p>We invite your call today. We'll schedule an appointment to look at your existing windows and give you a free, no hassle Exact Price. (Why give you an estimate when we can be more precise?)</p>
      <p>Below is a link to a page showing the U-Factors of all windows currently being sold in Wisconsin to better help you make an intelligent decision.</p>
      <p>Give us a call at <strong>715-551-0727</strong> to schedule a free estimate appointment today!</p>
      <p>Thank You,<br /><br />
        Paul J. Lenzner<br />
        Head Contractor</p>
    </div>
  );
}

export default About;
import React from "react"
import SimpleImageSlider from "react-simple-image-slider";
import hero from "../images/hero.jpg";

const images = [
    { url: hero },
];

function HeroSlider(props) {
    return (
        <div className="hero_slider">
            <SimpleImageSlider width={"100%"} height={475} images={images} />
        </div>
    )
}

export default HeroSlider;
import React from 'react';

function Exterior() {
  return (
    <div className="page-content exterior">
      <h2 className="page-title">Exterior Remodeling</h2>
    </div>
  );
}

export default Exterior;
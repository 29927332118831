import React from 'react';

function Contact() {
  return (
    <div className="page-content contact">
      <h2 className="page-title">Contact Us</h2>
    </div>
  );
}

export default Contact;
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './views/Home.js';
import About from './views/About.js';
import Windows from './views/Windows.js';
import Interior from './views/Interior.js';
import Exterior from './views/Exterior.js';
import Contact from './views/Contact.js';
import Gallery from './views/Gallery.js';

function BrowserRouterLinks(props) {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/windows" component={Windows} />
            <Route path="/interior" component={Interior} />
            <Route path="/exterior" component={Exterior} />
            <Route path="/contact" component={Contact} />
            <Route path="/gallery" component={Gallery} />
        </Switch>
    );
}

export default BrowserRouterLinks;
import React from 'react';

function Gallery() {
  return (
    <div className="page-content gallery">
      <h2 className="page-title">Photo Gallery</h2>
    </div>
  );
}

export default Gallery;